import gql from 'graphql-tag';

export default gql`
mutation delete_sme_brands_by_pk($id: Int!) {
  delete_sme_brands_by_pk(id: $id) {
    created_at
    id
    name
    sme_id
    updated_at
  }
}
`;