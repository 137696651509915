import gql from "graphql-tag";

export default gql`
mutation coSettingShippingProvider($sme_warehouse_ids: [Int], $service_code: String, $providers_connected_id: Int, $is_shipping_provider_auto_select: Int) {
  coSettingShippingProvider(is_shipping_provider_auto_select: $is_shipping_provider_auto_select, providers_connected_id: $providers_connected_id, service_code: $service_code, sme_warehouse_ids: $sme_warehouse_ids) {
    message
    success
  }
}

`;