import { useMutation, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { injectIntl, useIntl } from "react-intl";
import { useToasts } from "react-toast-notifications";
import DateRangePicker from 'rsuite/DateRangePicker';
import * as Yup from "yup";
import mutate_scReloadRatingComment from "../../../../../graphql/mutate_scReloadRatingComment";
import query_scFindTrackingLoadRating from "../../../../../graphql/query_scFindTrackingLoadRating";
import query_sc_stores_basic from "../../../../../graphql/query_sc_stores_basic";
import { LoadingDialog } from "../../../../../_metronic/_partials/controls";

function ModalTrackingLoadRating({ show, onHide, store }) {
  const { addToast } = useToasts();
  const [valueRangeTime, setValueRangeTime] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const { combine, allowedMaxDays, afterToday, before } = DateRangePicker;
  const [sessionReloading, setSessionReloading] = useState(false);
  const { formatMessage } = useIntl()
  const { data: trackingLoadRating, refetch } = useQuery(query_scFindTrackingLoadRating, {
    variables: {
      store_id: store?.id
    },
    fetchPolicy: "no-cache"
  });

  const { data: dataStore } = useQuery(query_sc_stores_basic, {
    fetchPolicy: 'cache-and-network'
  });

  const _onHide = () => {
    setDisabled(false);
    setValueRangeTime(null)
    onHide();
    setSessionReloading(false)
  }

  useEffect(() => {
    let interval = null;
    if (show) {
      const callApi = () => {
        refetch();
      }

      let tracking = trackingLoadRating?.scFindTrackingLoadRating
      if (!!tracking) {
        if (tracking?.total_job_load > tracking?.total_job_load_processed) {
          // nếu tồn tại dữ liệu thì tức là có tiến trình đang chạy => hiện progress
          setSessionReloading(true);
          interval = setInterval(callApi, 1000)
        }
        else {
          if (!!interval)
            clearInterval(interval)
        }
      }
    }
    else {
      if (!!interval) clearInterval(interval)
    }
    return () => {
      if (!!interval) clearInterval(interval)
    }
  }, [show, store, trackingLoadRating]);

  let storeInfo = (store_id) => {
    const store = dataStore?.sc_stores?.find((st) => st.id == store_id);
    const channel = dataStore?.op_connector_channels?.find((st) => st.code == store?.connector_channel_code);
    return {
      logo: channel?.logo_asset_url,
      name: store?.name
    }
  }

  const [mutateLoadRating, { loading }] = useMutation(mutate_scReloadRatingComment)

  const getPercent = (a, b) => {
    if (a >= b || b == 0) return "100";
    return (a / b * 100).toFixed()
  }

  return (
    <Formik
      initialValues={{ range_time: [] }}
      validationSchema={Yup.object().shape({
        range_time: Yup.array().required(`${formatMessage({ defaultMessage: "Vui lòng chọn thời gian đánh giá" })}`)
      })}
      enableReinitialize
      onSubmit={async values => {
        let variablesLoad = {
          store_id: store?.id,
          range_time: [dayjs(valueRangeTime[0]).endOf('day').unix(), dayjs(valueRangeTime[1]).startOf('day').unix()],
        }
        const { data } = await mutateLoadRating({
          variables: variablesLoad
        });
        if (data?.scReloadRatingComment?.success) {
          addToast(formatMessage({ defaultMessage: 'Bắt đầu tải đánh giá' }), { appearance: 'success' });
          refetch()
          setDisabled(true)
        } else {
          addToast(data?.scReloadRatingComment?.message || formatMessage({ defaultMessage: 'Tải lại lỗi' }), { appearance: 'error' });
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm
      }) => {
        return (
          <Modal
            show={show}
            aria-labelledby="example-modal-sizes-title-lg"
            centered
            onHide={() => {
              resetForm()
              _onHide()
            }}
          >
            <LoadingDialog show={loading} />
            <Modal.Header>
              <Modal.Title>
                {formatMessage({ defaultMessage: 'Tải đánh giá' })}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default" >
              {!sessionReloading ?
                <div className="col-12" >
                  <div>
                    <div className="fs-14 mb-3">{formatMessage({ defaultMessage: 'Gian hàng' })}:
                      <span className="ml-2">
                        <img
                          src={storeInfo(store?.id)?.logo}
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                        />
                        <span className="ml-1">{storeInfo(store?.id)?.name}</span>
                      </span>
                    </div>
                  </div>

                  <div className="row mt-3 display-flex align-items-center" >
                    <label className="col-4 col-form-label" style={{ color: '#000000' }}>
                      {formatMessage({ defaultMessage: 'Thời gian đánh giá' })}
                      <span className='text-danger' > *</span></label>
                    <div className="col-8">
                      <DateRangePicker
                        style={{
                          float: 'right',
                          width: '100%',
                        }}
                        character={' - '}
                        format={'dd/MM/yyyy'}
                        value={valueRangeTime}
                        placeholder={'dd/mm/yyyy - dd/mm/yyyy'}
                        placement={'auto'}
                        name="range_time"
                        onChange={range => {
                          if (range) {
                            setValueRangeTime(range);
                            setFieldValue('range_time', range)
                          } else {
                            setValueRangeTime([]);
                            setFieldValue('range_time', [])
                          }
                        }}
                        shouldDisableDate={combine(before(Date.now() - 90 * 24 * 3600000), combine(allowedMaxDays(7), afterToday()))}
                        size='sm'
                        locale={{
                          sunday: 'CN',
                          monday: 'T2',
                          tuesday: 'T3',
                          wednesday: 'T4',
                          thursday: 'T5',
                          friday: 'T6',
                          saturday: 'T7',
                          ok: formatMessage({ defaultMessage: 'Đồng ý' }),
                          today: formatMessage({ defaultMessage: 'Hôm nay' }),
                          yesterday: formatMessage({ defaultMessage: 'Hôm qua' }),
                          hours: formatMessage({ defaultMessage: 'Giờ' }),
                          minutes: formatMessage({ defaultMessage: 'Phút' }),
                          seconds: formatMessage({ defaultMessage: 'Giây' }),
                          formattedMonthPattern: 'MM/yyyy',
                          formattedDayPattern: 'dd/MM/yyyy',
                          // for DateRangePicker
                          last7Days: formatMessage({ defaultMessage: '7 ngày qua' })
                        }}
                      />

                    </div>

                  </div>
                  <div className="row">
                    <div className="col-4"></div>
                    <div className="col-8">
                      {(errors?.range_time && touched['range_time']) && <div className="invalid-feedback d-block">{errors?.range_time}</div>}

                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <i className="fas fa-info-circle mr-2" style={{ fontSize: 14 }}></i>
                      {formatMessage({ defaultMessage: 'Hệ thống cho phép một lần tải lại tối đa 1 tuần' })}
                    </div>
                  </div>
                </div>
                :
                <div className="row">
                  <div className="col-12">
                    <div className="fs-14 mb-3">{formatMessage({ defaultMessage: 'Gian hàng' })}:
                      <span className="ml-2">
                        <img
                          src={storeInfo(trackingLoadRating?.scFindTrackingLoadRating?.store_id)?.logo}
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                        />
                        <span className="ml-1">{storeInfo(trackingLoadRating?.scFindTrackingLoadRating?.store_id)?.name}</span>
                      </span>
                    </div>
                    <div className="fs-14 mb-3">
                      {formatMessage({ defaultMessage: 'Thời gian đánh giá' })} : {`${dayjs.unix(trackingLoadRating?.scFindTrackingLoadRating?.start_time).format("DD/MM/YYYY")}`} - {`${dayjs.unix(trackingLoadRating?.scFindTrackingLoadRating?.finish_time).format("DD/MM/YYYY")}`}
                    </div>
                    <div className="fs-14 mb-3">
                      <ProgressBar
                        style={{ height: '30px', fontSize: '14px' }}
                        now={getPercent(trackingLoadRating?.scFindTrackingLoadRating?.total_job_load_processed, trackingLoadRating?.scFindTrackingLoadRating?.total_job_load)}
                        label={`${getPercent(trackingLoadRating?.scFindTrackingLoadRating?.total_job_load_processed, trackingLoadRating?.scFindTrackingLoadRating?.total_job_load)}%`} />
                    </div>
                    <div className="fs-14 mb-3">
                      {formatMessage({ defaultMessage: 'Số lượng đánh giá tải thành công' })}: <span style={{ color: "#00DB6D" }} >{trackingLoadRating?.scFindTrackingLoadRating?.total_rating_success}</span>
                    </div>
                    <div className="fs-14 mb-3">
                      {formatMessage({ defaultMessage: 'Số lượng đánh giá tải thất bại' })}: <span style={{ color: "#F80D0D" }} >{trackingLoadRating?.scFindTrackingLoadRating?.total_rating_fail}</span>
                    </div>
                  </div>
                </div>
              }

            </Modal.Body>
            <Modal.Footer className="form" >
              <div className="form-group">
                <button
                  type="button"
                  onClick={() => {
                    resetForm()
                    _onHide()
                  }}
                  className="btn btn-secondary mr-3"
                  style={{ width: 100 }}
                  disabled={loading}
                >
                  {formatMessage({ defaultMessage: 'Đóng' })}
                </button>
                {!sessionReloading &&
                  <button
                    type="button"
                    className="btn btn-primary btn-elevate"
                    style={{ width: 100 }}
                    disabled={disabled}
                    onClick={handleSubmit}
                  >
                    {formatMessage({ defaultMessage: 'Tải đánh giá' })}

                  </button>}
              </div>
            </Modal.Footer>
          </Modal >
        )
      }}
    </Formik>
  );
}

export default injectIntl(ModalTrackingLoadRating);