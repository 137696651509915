import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import query_sme_brands from "../../../../../graphql/query_sme_brands";
import {useLocation} from 'react-router-dom'
import queryString from 'querystring'
import LoadingDialog from "../../../ProductsStore/product-new/LoadingDialog";
import { useIntl } from "react-intl";
import Pagination from "../../../../../components/Pagination";
import ModalUpdateBrand from "./ModalUpdateBrand";
import ModalConfirm from "./ModalConfirm";
import AuthorizationWrapper from "../../../../../components/AuthorizationWrapper";
import ModalAddBrands from "./ModalAddBrands";


export default function BrandTable() {
    const params = queryString.parse(useLocation().search.slice(1, 100000))
    const {formatMessage} = useIntl()
    const [showUpdate, setShowUpdate] =  useState(false)
    const [showConfirm, setShowConfirm] =  useState(false)
    const [brand, setBrand] =  useState(null)
    const [showAdd, setShowAdd] = useState(false)

    let page = useMemo(() => {
        try {
          let _page = Number(params.page)
          if (!Number.isNaN(_page)) {
            return Math.max(1, _page)
          } else {
            return 1
          }
        } catch (error) {
          return 1
        }
      }, [params.page])
    let limit = useMemo(() => {
        try {
          let _value = Number(params.limit)
          if (!Number.isNaN(_value)) {
            return Math.max(25, _value)
          } else {
            return 25
          }
        } catch (error) {
          return 25
        }
    }, [params.limit]);
    const { data, loading, error } = useQuery(query_sme_brands, {
            variables: {
                limit,
                offset: (page - 1) * limit,
            },
            fetchPolicy: 'cache-and-network'
    });

    const totalRecord = data?.sme_brands_aggregate?.aggregate?.count
    const totalPage = Math.ceil(totalRecord / limit)
    return (
        <>
        <LoadingDialog show={loading} />
        {showUpdate && !!brand && <ModalUpdateBrand isShow={showUpdate} 
            onHide={() => {
                setShowUpdate(false)
                setBrand(null)
            }}
            brand={brand}
        />}
        {showConfirm && !!brand && <ModalConfirm show={showConfirm} 
            onHide={() => {
                setShowConfirm(false)
                setBrand(null)
            }}
            brand={brand}
        />}
        {!!showAdd && <ModalAddBrands isShow={showAdd} onHide={() => {
        setShowAdd(false)
      }}/>}
        <div className="d-flex justify-content-between my-3">
                <p style={{fontSize: 14}}><strong>{formatMessage({defaultMessage: "Danh sách nhãn hàng"})}</strong></p>
                <AuthorizationWrapper keys={['setting_brands_action']}>
                    {totalRecord < 20 && <button onClick={() => setShowAdd(true)} className="btn btn-primary">{formatMessage({defaultMessage: 'Thêm nhãn hàng'})}</button>}
                </AuthorizationWrapper>
            </div>
        <table className="table table-borderless table-vertical-center fixed">
            <thead
            style={{
                borderBottom: '1px solid #F0F0F0',
                borderRight: '1px solid #d9d9d9',
                borderLeft: '1px solid #d9d9d9',
                background: "#F3F6F9",
                fontWeight: "bold",
                fontSize: "14px",
                position: 'sticky',
                top: 44,
                zIndex: 10
            }}
            >
            <tr className="font-size-lg">
                <th style={{ fontSize: '14px', width: "150px" }} className="pl-6">{formatMessage({ defaultMessage: "ID" })}</th>
                <th style={{ fontSize: '14px' }} >{formatMessage({ defaultMessage: "Tên nhãn hàng" })}</th>
                <th className="text-center" style={{ fontSize: '14px' }}>{formatMessage({ defaultMessage: "Ngày cập nhật" })}</th>
                <th style={{ width: "150px", fontSize: '14px' }} className="text-center">{formatMessage({ defaultMessage: "Thao tác" })}</th>
            </tr>
            </thead>
            <tbody>
                {!loading && data?.sme_brands?.map(brand => {
                    return <tr>
                            <td className="pl-6" style={{ borderLeft: '1px solid #d9d9d9' }}>{brand?.id}</td>
                            <td>
                                <span>{brand?.name}</span>
                                <i
                                    role="button"
                                    className="ml-2 text-dark far fa-edit"
                                    onClick={() => {
                                        setBrand(brand)
                                        setShowUpdate(true)
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                {/* {productStore(store)} */}
                                {brand?.updated_at ? dayjs(brand?.updated_at).format('HH:mm DD/MM/YYYY') : '--'}

                            </td>
                            <td className="text-center">
                                <span 
                                    style={{
                                        color: '#FF0000',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        setShowConfirm(true)
                                        setBrand(brand)
                                    }}
                                >{formatMessage({defaultMessage: 'Xóa'})}</span>
                            </td>
                    </tr>
                })}
            </tbody>
        </table>
        {!error && !loading && (
        <Pagination
          page={page}
          totalPage={totalPage}
          loading={loading}
          limit={limit}
          totalRecord={totalRecord}
          count={data?.sme_brands?.length}
          basePath={'/setting/brands'}
          emptyTitle={formatMessage({ defaultMessage: 'Chưa có nhãn hàng nào' })}
        />
      )}
        </>
    )
}
