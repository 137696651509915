import gql from "graphql-tag";

export default gql`
    query query_scFindTrackingLoadRating($store_id: Int!) {
        scFindTrackingLoadRating(store_id: $store_id) {
        total_job_load
        total_job_load_processed
        store_id
        start_time
        finish_time
        total_rating_fail
        total_rating_success
        }
    } 
`;
