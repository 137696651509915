// import queryString from 'querystring';
import React, { useEffect, useMemo, useState } from "react";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Helmet } from 'react-helmet-async';
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { Card, CardBody, } from "../../../../_metronic/_partials/controls";
import { useSubheader } from "../../../../_metronic/layout";
import query_prvListProvider from '../../../../graphql/query_prvListProvider';
import { useMutation, useQuery } from '@apollo/client';
import Select from "react-select";
import query_sme_catalog_stores from '../../../../graphql/query_sme_catalog_stores';
import query_coGetSettingShippingProvider from '../../../../graphql/query_coGetSettingShippingProvider';
import mutate_coSettingShippingProvider from '../../../../graphql/mutate_coSettingShippingProvider';
import LoadingDialog from '../../ProductsStore/products-list/dialog/LoadingDialog';
import { useToasts } from "react-toast-notifications";

const SHIPPING_MANUAL = 0;
const SHIPPING_AUTO = 1;
const SELECT_ALL = 0;

function SettingShipping() {
    const location = useLocation()
    const { formatMessage } = useIntl()
    const { appendBreadcrumbs, setBreadcrumbs } = useSubheader()
    const [shippingConfig, setShippingConfig] = useState({})
    const { addToast } = useToasts();

    const SHIPPNG_PROVIDER_OPTION = [
        { value: SHIPPING_AUTO, label: formatMessage({ defaultMessage: 'Tự động chọn ĐVVC' }) },
        { value: SHIPPING_MANUAL, label: formatMessage({ defaultMessage: 'Chọn ĐVVC thủ công' }) }]

    useEffect(() => {
        let title = formatMessage({ defaultMessage: 'Cấu hình vận chuyển' })
        setBreadcrumbs([])
        appendBreadcrumbs({
            title: formatMessage({ defaultMessage: 'Cài đặt' }),
            pathname: `/setting`
        })
        appendBreadcrumbs({
            title: title,
            pathname: `/setting/channels`
        })
    }, [location.pathname])

    const { data: dataProvider, loading: loadingProvider } = useQuery(query_prvListProvider, {
        fetchPolicy: "cache-and-network",
        variables: {
            list_category_code: ['logistic']
        }
    });

    const [mutate, { loading: loadingMutateSetShippingProvider }] = useMutation(mutate_coSettingShippingProvider, {
        refetchQueries: ['coGetSettingShippingProvider'],
        awaitRefetchQueries: true,
        onCompleted: (data) => {
            data?.coSettingShippingProvider
            if (data?.coSettingShippingProvider?.success)
                addToast(formatMessage({ defaultMessage: "Cập nhật thành công" }), { appearance: 'success' })
            else addToast(data?.coSettingShippingProvider?.message, { appearance: 'error' })
        },
        onError: () => {
            addToast(formatMessage({ defaultMessage: "Cập nhật thất bại" }), { appearance: 'error' })
        }
    })

    const { loading: loadingSetting } = useQuery(query_coGetSettingShippingProvider,
        {
            onCompleted: (data) => {
                const { providers_connected_id, service_code, sme_warehouse_ids, is_shipping_provider_auto_select } = data?.coGetSettingShippingProvider;
                setShippingConfig({
                    providers_connected_id,
                    service_code,
                    sme_warehouse_ids: Array.isArray(sme_warehouse_ids) ? sme_warehouse_ids : [sme_warehouse_ids],
                    is_shipping_provider_auto_select
                })
            }
        })

    const { data: dataSmeWarehouse, loading: loadingWh } = useQuery(query_sme_catalog_stores, {
        fetchPolicy: 'cache-and-network'
    });

    const [shippingProvider] = useMemo(() => {
        const _shippingProvider = (dataProvider?.prvListProvider?.data
            ?.filter(_ => !!_?.providerConnected?.length) ?? [])// lọc ra các đơn vị đang active
            .map(_ => {
                return {
                    label: _?.name,
                    value: _?.providerConnected[0]?.id
                }
            })
        return [_shippingProvider]
    }, [dataProvider, shippingUnit])

    const optionWh = useMemo(() => {
        const whOpt = [{
            value: SELECT_ALL,
            label: formatMessage({ defaultMessage: 'Tất cả' })
        }];
        whOpt.push(...(dataSmeWarehouse?.sme_warehouses?.map(_ => ({
            value: _?.id,
            label: _?.name
        })) ?? []))

        return whOpt
    }, [dataSmeWarehouse])

    const wh = useMemo(() => {
        const _wh = optionWh.filter(_ => shippingConfig?.sme_warehouse_ids?.includes(_?.value));
        return _wh
    }, [shippingConfig, optionWh])

    const shippingUnit = useMemo(() => {
        const _shippingUnit = shippingProvider?.find(_ => _?.value == shippingConfig?.providers_connected_id);
        return _shippingUnit
    }, [shippingConfig, shippingProvider])

    const services = useMemo(() => {
        const _su = dataProvider?.prvListProvider?.data?.find(_ => _?.providerConnected[0]?.id == shippingUnit?.value);
        const _services = _su?.logistic_services?.map(sv => {
            return {
                value: sv?.code,
                label: sv?.label
            }
        })

        return _services
    }, [dataProvider, shippingUnit])

    const service = useMemo(() => {
        const _services = services?.find(_ => _?.value == shippingConfig?.service_code)
        return _services
    }, [shippingConfig, services])

    const isShippingProviderAutoSelect = useMemo(() => {
        const _isShippingProviderAutoSelect = SHIPPNG_PROVIDER_OPTION.find(_ => _.value == shippingConfig?.is_shipping_provider_auto_select)
        return _isShippingProviderAutoSelect
    }, [shippingConfig])

    const requireSippingUnit = isShippingProviderAutoSelect?.value == SHIPPING_MANUAL;
    const requireService = isShippingProviderAutoSelect?.value == SHIPPING_MANUAL

    // console.log({ shippingConfig, service, shippingUnit, wh });
    return (
        <>
            <Helmet
                titleTemplate={formatMessage({ defaultMessage: "Cấu hình vận chuyển " }) + "- UpBase"}
                defaultTitle={formatMessage({ defaultMessage: "Cấu hình vận chuyển " }) + "- UpBase"}
            >
                <meta name="description" content={formatMessage({ defaultMessage: "Cấu hình vận chuyển " }) + "- UpBase"} />
            </Helmet>
            <LoadingDialog show={loadingSetting || loadingMutateSetShippingProvider} />
            <Card>
                <CardBody>
                    <div className='row col-7' style={{ fontSize: "15px" }}>
                        <span className='col-4 text-right py-2'>
                            {formatMessage({ defaultMessage: "Cấu hình ĐVVC" })}
                        </span>
                        <div className='col-8 py-2 row'>
                            {
                                SHIPPNG_PROVIDER_OPTION.map((op, idx) => {
                                    const isActive = op.value == isShippingProviderAutoSelect?.value;

                                    if (op.value == SHIPPING_AUTO) // tạm ẩn trường hợp tự động
                                        return null
                                    return (
                                        <label className={'col-' + 12 / SHIPPNG_PROVIDER_OPTION.length + " d-flex align-items-center"}
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                setShippingConfig(sf => ({ ...sf, is_shipping_provider_auto_select: op.value }))
                                            }}>
                                            <div style={{
                                                width: 14,
                                                height: 14,
                                                borderColor: isActive ? "#ff5629" : "#999",
                                                borderStyle: "solid",
                                                borderWidth: isActive ? 3 : 1,
                                                borderRadius: 16,
                                                marginRight: 6
                                            }} /> {op.label}
                                        </label>
                                    )
                                })
                            }
                        </div>
                        {requireSippingUnit &&
                            <>
                                <span className='col-4 text-right py-2' style={{ lineHeight: "38px" }}>{formatMessage({ defaultMessage: "ĐVVC mặc định" })}<span className="text-danger">*</span>:</span>
                                <div className='col-8 py-2 pr-0'>
                                    <Select
                                        value={shippingUnit}
                                        options={shippingProvider}
                                        className='w-100'
                                        isLoading={loadingProvider || loadingMutateSetShippingProvider}
                                        placeholder={formatMessage({ defaultMessage: 'Chọn ĐVVC' })}
                                        onChange={value => {
                                            setShippingConfig(sc => ({ ...sc, providers_connected_id: value?.value, service_code: null }))
                                        }}
                                    />
                                </div>
                            </>}
                        {requireService &&
                            <>
                                <span className='col-4 text-right py-2' style={{ lineHeight: "38px" }}>{formatMessage({ defaultMessage: "Dịch vụ" })}<span className="text-danger">*</span>:</span>
                                <div className='col-8 py-2 pr-0'>
                                    <Select
                                        value={service || ""}
                                        options={services}
                                        isClearable
                                        className='w-100'
                                        isLoading={loadingProvider || loadingMutateSetShippingProvider}
                                        placeholder={formatMessage({ defaultMessage: 'Chọn dịch vụ' })}
                                        onChange={value => {
                                            setShippingConfig(sc => ({ ...sc, service_code: value?.value }))
                                        }}
                                    />
                                </div>
                            </>}


                        <span className='col-4 text-right py-2' style={{ lineHeight: "38px" }}>{formatMessage({ defaultMessage: "Kho áp dụng" })}<span className="text-danger">*</span>:</span>
                        <div className='col-8 py-2 pr-0'>
                            <Select
                                value={wh}
                                options={optionWh}
                                className='w-100'
                                isMulti
                                isLoading={loadingMutateSetShippingProvider || loadingWh}
                                placeholder={formatMessage({ defaultMessage: 'Chọn kho vật lý' })}
                                onChange={value => {
                                    // console.log("xxxx", value);
                                    let isSelectAll = false;
                                    value?.forEach(_ => {
                                        if (_?.value === SELECT_ALL) {
                                            isSelectAll = true;
                                        }
                                    })

                                    setShippingConfig(sc =>
                                    ({
                                        ...sc, sme_warehouse_ids: isSelectAll
                                            ? optionWh.filter(_ => _?.value != SELECT_ALL)?.map(_ => _?.value)
                                            : value?.map(_ => _?.value)
                                    })
                                    )
                                }}
                            />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end col-7 row">
                        <button
                            disabled={loadingProvider || loadingWh
                                || loadingMutateSetShippingProvider  //loading
                                || (requireSippingUnit && !shippingConfig?.providers_connected_id) // không có đvvc nếu thanh chọn đvvc được hiện
                                || (requireService && !shippingConfig?.service_code) // không có dịch vụ nếu thanh chọn dịch vụ được hiện
                                || !shippingConfig?.sme_warehouse_ids?.length} // không có kho
                            className={`btn btn-primary font-weight-bold px-9 py-2`}
                            onClick={() => {
                                mutate({ variables: shippingConfig })
                            }}
                        >
                            <span className="font-weight-boldest">{formatMessage({ defaultMessage: 'Cập nhật' })}</span>

                        </button>
                    </div>

                </CardBody>
            </Card>
        </>
    )
}

export default SettingShipping


export const actionKeys = {
    "group_setting_shipping_configuration_view": {
        router: '/setting/setting-shipping',
        actions: [
            'sme_warehouses', 'coGetSettingShippingProvider', 'prvListProvider'
        ],
        name: 'Thông tin cấu hình vận chuyển',
        group_code: 'group_setting_shipping_configuration',
        group_name: 'Cấu hình vận chuyển',
        cate_code: 'category_setting_service',
        cate_name: 'Cài đặt',
    },
    "group_setting_shipping_configuration_action": {
        router: '/setting/setting-shipping',
        actions: [
            'sme_warehouses', 'coGetSettingShippingProvider', 'prvListProvider', 'coSettingShippingProvider'
        ],
        name: 'Các thao tác trong màn cấu hình vận chuyển',
        group_code: 'group_setting_shipping_configuration',
        group_name: 'Cấu hình vận chuyển',
        cate_code: 'category_setting_service',
        cate_name: 'Cài đặt',
    }
};