import React, { memo } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useMutation } from "@apollo/client";
import mutate_delete_sme_brands_by_pk from '../../../../../graphql/mutate_delete_sme_brands_by_pk';
import { useToasts } from 'react-toast-notifications';

const ModalConfirm = memo(({
    show,
    onHide,
    brand,
}) => {
    const { formatMessage } = useIntl();
    const { addToast } = useToasts();

    const [mutate, { loading }] = useMutation(mutate_delete_sme_brands_by_pk, {
        refetchQueries: ['sme_brands', 'sme_brands_aggregate'],
    })

    return (
        <Modal
            show={show}
            aria-labelledby="example-modal-sizes-title-md"
            dialogClassName={"body-dialog-scheduled-frame"}
            centered
            onHide={onHide}
            backdrop={true}
        >
            <Modal.Body className="overlay overlay-block cursor-default">
                <div className='text-center'>
                    <div className="mb-10" >
                        {formatMessage({defaultMessage: 'Hệ thống sẽ xóa tất cả thông tin liên quan đến nhãn hàng này. Bạn không thực hiện lọc được dữ liệu với nhãn hàng này được nữa. Bạn có đồng ý xóa?'})}
                    </div>
                    <div className="form-group mb-0">
                        <button
                            id="kt_login_signin_submit"
                            className="btn btn-light btn-elevate mr-3"
                            style={{ width: 120 }}
                            disabled={loading}
                            onClick={e => {
                                e.preventDefault();
                                onHide();
                            }}
                        >
                            <span className="font-weight-boldest">
                                {formatMessage({ defaultMessage: 'Hủy bỏ' })}
                            </span>
                        </button>
                        <button
                            className={`btn btn-primary font-weight-bold`}
                            style={{ width: 120 }}
                            disabled={loading}
                            onClick={async () => {
                                let {data} = await mutate({
                                    variables: {
                                        id: +brand?.id
                                    }
                                })
                                if(data?.delete_sme_brands_by_pk?.id) {
                                    addToast(formatMessage({defaultMessage:'Xóa nhãn hàng thành công.'}), { appearance: 'success' });
                                } else {
                                    addToast(formatMessage({defaultMessage:'Xóa nhãn hàng thất bại.'}), { appearance: 'error' });
                                }
                                onHide()
                            }}
                        >
                            <span className="font-weight-boldest">
                                {formatMessage({ defaultMessage: 'Đồng ý' })}
                            </span>
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
});

export default ModalConfirm;