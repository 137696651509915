import gql from 'graphql-tag';

export default gql`
mutation userCreateBrand($userCreateBrandInput: UserCreateBrandInput) {
  userCreateBrand(userCreateBrandInput: $userCreateBrandInput) {
    message
    success
  }
}

`;