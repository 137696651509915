import gql from 'graphql-tag';

export default gql`
mutation userUpdateBrand($userUpdateBrandInput: UserUpdateBrandInput) {
  userUpdateBrand(userUpdateBrandInput: $userUpdateBrandInput) {
    message
    success
  }
}

`;