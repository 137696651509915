import React, { useCallback, useMemo, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import op_connector_channels from '../../../../graphql/op_connector_channels'
import scSaleAuthorizationUrl from '../../../../graphql/scSaleAuthorizationUrl'
import { useLazyQuery, useQuery } from "@apollo/client";
// import { useLocation } from "react-router";
// import { useIntl } from "react-intl";
import AddStoreDialog from "./AddStoreDialog";
function ChannelsAddDialog({ show, onHide }) {
  // const [channelsSelected, setChannelsSelected] = useState()
  const [addStoreDialog, setAddStoreDialog] = useState()
  const { data } = useQuery(op_connector_channels)
  const [authorize, { data: dataAuthozie, loading }] = useLazyQuery(scSaleAuthorizationUrl)
  // const location = useLocation()
  // const { formatMessage } = useIntl()

  const _onHide = useMemo(() => {
    if (!!dataAuthozie || loading) {
      return null
    }
    return onHide
  }, [dataAuthozie, loading])

  const saveClick = useCallback((channelSelected) => {
    if (channelSelected == 'other') {
      setAddStoreDialog(true)
      // setChannelsSelected()
      return
    }
    authorize({
      variables: {
        connector_channel_code: channelSelected
      }
    })
  }, []);

  useMemo(() => {
    if (!!dataAuthozie && !!dataAuthozie.scSaleAuthorizationUrl && !!dataAuthozie.scSaleAuthorizationUrl.authorization_url) {
      window.location.replace(dataAuthozie.scSaleAuthorizationUrl.authorization_url)
    }

  }, [dataAuthozie])

  return (
    <Modal
      show={show}
      onHide={() => {
        // setChannelsSelected()
        _onHide()
      }}
      aria-labelledby="example-modal-sizes-title-lg"
      centered
      size='lg'
      backdrop={(!_onHide) ? 'static' : true}
      dialogClassName='width-fit-content'
    >
      <Modal.Header className="px-4 py-4">
        <h5><FormattedMessage defaultMessage="Thêm gian hàng" /></h5>
        <div className="cursor-pointer" onClick={() => { _onHide() }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default py-4 px-2" style={{ width: 600 }} >
        <AddStoreDialog show={addStoreDialog} onHide={() => {
          onHide()
          setAddStoreDialog(false)
        }} />
        <div className="px-4 flex-column d-flex">
          {
            (data?.op_connector_channels || []).map(_channel => {
              return (
                <div key={_channel.code} className="mx-8 mt-3 mb-1 d-flex justify-content-between align-items-center" style={{ width: "160px" }}>
                  <div className="d-flex align-items-center">
                    <div style={{ width: 30, height: 30, marginRight: "8px" }}>{_channel.logo_asset_url && <img src={_channel.logo_asset_url} className={` mr-2`}
                      style={{ width: 30 }} />}
                    </div>
                    {_channel.name}
                  </div>
                  <span className="mx-2 text-primary cursor-pointer"
                    onClick={() => {
                      saveClick(_channel.code)
                    }}
                  >Thêm +</span>
                </div>
              )
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer className="form px-4 py-2" style={{ justifyContent: 'end' }} >
        <div className="form-group">
          <button
            type="button"
            onClick={() => {
              // setChannelsSelected()
              _onHide()
            }}
            disabled={!_onHide}
            className="btn btn-primary"
            style={{ width: 100 }}
          >
            <span className="font-weight-boldest"><FormattedMessage defaultMessage="Đóng" /></span>
          </button>
          {/* <button
            id="kt_login_signin_submit"
            onClick={saveClick}
            disabled={!channelsSelected || !_onHide}
            className={`btn btn-primary font-weight-bold px-9 `}
            style={{ width: 150 }}
          >
            <span className="font-weight-boldest"><FormattedMessage defaultMessage="THÊM" /></span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button> */}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ChannelsAddDialog;