import Table from 'rc-table';
import 'rc-table/assets/index.css';
import React, { Fragment, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import PaginationModal from '../../../../components/PaginationModal';

const ListWarehouseDialog = ({
    showSelectWh,
    onHideSelectWh,
}) => {
    const [page, setPage] = useState(1)
    const { formatMessage } = useIntl();
    const dataWh = (showSelectWh || [])
    // const dataWh = [..._dataWh, ..._dataWh, ..._dataWh, ..._dataWh]
    const totalRecord = dataWh?.length || 0;
    const totalPage = Math.ceil(dataWh?.length / 5);

    const _onHideSelectWh = () => {
        onHideSelectWh()
    }

    return <Modal
        show={!!showSelectWh}
        aria-labelledby="example-modal-sizes-title-xl"
        centered
        size="lg"
        backdrop={true}
        onHide={() => { _onHideSelectWh() }}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                {formatMessage({ defaultMessage: 'Chọn kho đẩy tồn' })}
                <div className='d-flex'
                    onClick={() => {
                        _onHideSelectWh()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <CloseIcon />
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
            <Fragment>
                <Table
                    className="upbase-table"
                    style={{ minHeight: 420 }}
                    columns={[
                        {
                            title: <div className='d-flex align-items-center'>
                                {formatMessage({ defaultMessage: 'Tên kho' })}
                            </div>,
                            dataIndex: 'idex',
                            key: 'idex',
                            align: 'left',
                            width: '40%',
                            render: (item, record) => {
                                return (
                                    <div className='d-flex align-items-center'>
                                        <div>
                                            <div>{record?.name}</div>
                                            <div style={{ color: "#aaa" }}>{formatMessage({ defaultMessage: 'Mã kho: ' })}&nbsp;{record?.code}</div>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: formatMessage({ defaultMessage: 'Địa chỉ kho' }),
                            dataIndex: 'id',
                            key: 'id',
                            align: 'left',
                            width: '60%',
                            render: (item, record) => {
                                return (
                                    <div className='d-flex flex-column'>
                                        {record?.address}
                                    </div>
                                )
                            }
                        },
                    ]}
                    data={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))}
                    emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                        <img src={toAbsoluteUrl("/media/empty.png")} alt="image" width={80} />
                        <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                    </div>}
                    tableLayout="auto"
                    sticky={{ offsetHeader: 0 }}
                />
                <PaginationModal
                    page={page}
                    totalPage={totalPage}
                    limit={5}
                    totalRecord={totalRecord}
                    count={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))?.length}
                    onPanigate={(page) => setPage(page)}
                    emptyTitle={formatMessage({ defaultMessage: 'Chưa có kho' })}
                />
            </Fragment>
        </Modal.Body>
        <Modal.Footer
            className="form p-4 d-flex justify-content-end"
            style={{ borderTop: '1px solid #dbdbdb' }}
        >
            <div className="form-group">
                <button
                    type="button"
                    onClick={() => { _onHideSelectWh() }}
                    className="btn btn-secondary"
                    style={{ width: 120 }}
                >
                    {formatMessage({ defaultMessage: 'Đóng' })}
                </button>
            </div>
        </Modal.Footer>
    </Modal>
}

const CloseIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg>
}

export default ListWarehouseDialog