import React, { memo, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation } from "@apollo/client";
import { Input, InputVertical } from '../../../../../_metronic/_partials/controls';
import { Field, Formik } from 'formik';
import * as Yup from "yup";
import { useToasts } from 'react-toast-notifications';
import { useIntl } from "react-intl";
import mutate_userUpdateBrand from '../../../../../graphql/mutate_userUpdateBrand';

const ModalUpdateBrand = ({
    onHide,
    isShow,
    brand
}) => {
    const {formatMessage} = useIntl()
    const _form = useRef(null)
    const [mutate, { loading }] = useMutation(mutate_userUpdateBrand, {
        refetchQueries: ['sme_brands', 'sme_brands_aggregate'],
    })
    const { addToast } = useToasts();

    useEffect(() => {
        if (!isShow) {
            !!_form.current && _form.current.resetForm()
        }
    }, [isShow])
    return (
        <Formik
            initialValues={{
                name: brand?.name
            }}
            onSubmit={async (values) => {
                let {data} = await mutate({
                    variables: {
                        userUpdateBrandInput: {
                            id: brand?.id,
                            name: values?.name || ''
                        },
                    }
                })
                if(data?.userUpdateBrand?.success) {
                    addToast(formatMessage({defaultMessage:'Cập nhật tên nhãn hàng thành công.'}), { appearance: 'success' });
                } else {
                    addToast(data?.userUpdateBrand?.message || formatMessage({defaultMessage:'Cập nhật tên nhãn hàng thất bại.'}), { appearance: 'error' });
                }
                onHide()
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string()
                    .max(120, formatMessage({ defaultMessage: "{name} tối đa {length} ký tự" }, { length: 120, name: formatMessage({ defaultMessage: "Tên nhãn hàng" }) }))
                    .required(formatMessage({ defaultMessage: "Vui lòng nhập {name}" }, { name: formatMessage({ defaultMessage: "tên nhãn hàng" }).toLowerCase() }))
                    .test(
                        'chua-ky-tu-space-o-dau-cuoi',
                        formatMessage({ defaultMessage: 'Tên nhãn hàng không được chứa dấu cách ở đầu và cuối' }),
                        (value, context) => {
                        if (!!value) {
                            return value.length == value.trim().length;
                        }
                        return false;
                        },
                    )
                    .test(
                        'chua-ky-tu-2space',
                        formatMessage({ defaultMessage: 'Tên nhãn hàng không được chứa 2 dấu cách liên tiếp' }),
                        (value, context) => {
                        if (!!value) {
                            return !(/\s\s+/g.test(value))
                        }
                        return false;
                        },
                    ),
            })}
            innerRef={_form}
        >
            {({
                handleSubmit,
                values,
                validateForm,
                setFieldError,
                submitForm,
            }) => {
                return (
                    <Modal
                        show={isShow}
                        aria-labelledby="example-modal-sizes-title-sm"
                        dialogClassName="modal-show-connect-product"
                        centered
                        onHide={onHide}
                        backdrop={true}

                    >
                        <Modal.Header closeButton={true}>
                            <Modal.Title>
                                {formatMessage({defaultMessage:"Cập nhật tên nhãn hàng"})}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            <div className="d-flex align-items-center py-2 px-4 mb-4" style={{ backgroundColor: '#CFF4FC', border: '1px solid #B6EFFB', borderRadius: 4 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style={{ color: '#055160' }} className="bi bi-lightbulb mr-2" viewBox="0 0 16 16">
                                <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z" />
                                </svg>
                                <span className="fs-14" style={{ color: '#055160' }}>
                                {formatMessage({ defaultMessage: 'Hệ thống sẽ cập nhật tất cả các bộ lọc theo tên nhãn hàng mới.' })}
                                </span>
                            </div>
                            <div className='row d-flex align-items-center'>
                                <div className='col-4'>
                                    {formatMessage({defaultMessage: 'Tên nhãn hàng'})}
                                </div>
                                <div className='col-8' >
                                    <Field
                                        name="name"
                                        component={InputVertical}
                                        placeholder="Điền tên nhãn hàng"
                                        required
                                        customFeedbackLabel={' '}
                                        countChar
                                        maxChar={120}
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="form" style={{ borderTop: '1px solid #dbdbdb', justifyContent: 'end', paddingTop: 10, paddingBottom: 10 }} >
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-secondary btn-elevate mr-3"
                                disabled={loading}
                            >
                                {formatMessage({defaultMessage:"Huỷ bỏ"})}
                            </button>
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary btn-elevate"
                                disabled={loading || !values?.name}
                            >
                                {formatMessage({defaultMessage:"Đồng ý"})}
                            </button>
                        </Modal.Footer>
                    </Modal>
                )
            }
            }
        </Formik>
    )
};

export default memo(ModalUpdateBrand);