import Table from 'rc-table';
import 'rc-table/assets/index.css';
import React, { Fragment, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { Checkbox } from '../../../../../_metronic/_partials/controls';
import PaginationModal from '../../../../../components/PaginationModal';

export const IS_PROCESS_ORDER = 1;
export const IS_NOT_PROCESS_ORDER = 0;

const SelectWHProcessDialog = ({
    showSelectWh,
    onHideSelectWh,
    sme_warehouses,
    setFieldValue,
    whSelected,
    setWhSelected,
    values
}) => {
    const [page, setPage] = useState(1)
    const [searchText, setSearchText] = useState('');
    const { formatMessage } = useIntl();
    const { sc_warehouse_id } = showSelectWh;

    const ws = whSelected[sc_warehouse_id]
    const _sme_warehouses = (sme_warehouses || [])?.filter(wh => {
        return !!ws?.find(_ => _?.sme_warehouse_id == wh?.id)
    })

    const totalRecord = _sme_warehouses?.length || 0;
    const totalPage = Math.ceil(_sme_warehouses?.length / 5);

    const dataWh = _sme_warehouses.filter(w => {
        if (!searchText) return true;
        return w?.name?.includes(searchText) || w?.code?.includes(searchText)
    })

    const _onHideSelectWh = () => {
        setSearchText('');
        onHideSelectWh()
    }

    const _selected = ws?.filter(_ => _?.is_process_order == IS_PROCESS_ORDER);

    return <Modal
        show={!!showSelectWh}
        aria-labelledby="example-modal-sizes-title-xl"
        centered
        size="lg"
        backdrop={true}
        onHide={() => { _onHideSelectWh() }}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                {formatMessage({ defaultMessage: 'Chọn kho xử lý đơn' })}
                <div className='d-flex'
                    onClick={() => {
                        _onHideSelectWh()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <CloseIcon />
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
            <Fragment>
                <div className="col-4 input-icon p-0" style={{ height: 'fit-content' }} >
                    <input
                        type="text"
                        className="form-control"
                        placeholder={formatMessage({ defaultMessage: "Tên/Mã kho" })}
                        onBlur={(e) => {
                            setSearchText(e.target.value)
                        }}
                        defaultValue={''}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                setSearchText(e.target.value)
                            }
                        }}
                    />
                    <span><i className="flaticon2-search-1 icon-md ml-6"></i></span>
                </div>
                <div className='py-4'>{formatMessage({ defaultMessage: "Đã chọn:" })} {_selected?.length ?? 0}/<span style={{ color: "#ff5629" }}>{_sme_warehouses?.length ?? "--"}</span></div>
                <Table
                    className="upbase-table"
                    style={{ minHeight: 420 }}
                    columns={[
                        {
                            title: <div className='d-flex align-items-center'>
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'checkbox',
                                    }}
                                    size='checkbox-md'
                                    isSelected={_selected?.length == _sme_warehouses?.length}
                                    onChange={(e) => {
                                        if (_selected?.length == _sme_warehouses?.length)
                                            setWhSelected(_ => (
                                                {
                                                    ..._,
                                                    [sc_warehouse_id]: ws?.map(__ => ({ ...__, is_process_order: IS_NOT_PROCESS_ORDER }))
                                                })
                                            )
                                        else
                                            setWhSelected(_ => (
                                                {
                                                    ..._,
                                                    [sc_warehouse_id]: ws?.map(__ => ({ ...__, is_process_order: IS_PROCESS_ORDER }))
                                                }
                                            ))
                                    }}
                                />
                                {formatMessage({ defaultMessage: 'Tên kho' })}
                            </div>,
                            dataIndex: 'idex',
                            key: 'idex',
                            align: 'left',
                            width: '40%',
                            render: (item, record) => {
                                const w = ws?.find(_ => _?.sme_warehouse_id == record?.id)
                                return (
                                    <div className='d-flex align-items-center'>
                                        <Checkbox
                                            inputProps={{
                                                'aria-label': 'checkbox',
                                            }}
                                            size='checkbox-md'
                                            isSelected={w?.is_process_order == IS_PROCESS_ORDER}
                                            onChange={(e) => {
                                                if (w?.is_process_order == IS_PROCESS_ORDER)
                                                    setWhSelected(_ => ({
                                                        ..._,
                                                        [sc_warehouse_id]: ws?.map(_w => {
                                                            if (w?.sme_warehouse_id == _w?.sme_warehouse_id)
                                                                return {
                                                                    ..._w, is_process_order: IS_NOT_PROCESS_ORDER
                                                                }
                                                            return _w
                                                        })
                                                    }))
                                                else {
                                                    setWhSelected(_ => (
                                                        {
                                                            ..._,
                                                            [sc_warehouse_id]: ws?.map(_w => {
                                                                if (w?.sme_warehouse_id == _w?.sme_warehouse_id)
                                                                    return {
                                                                        ..._w, is_process_order: IS_PROCESS_ORDER
                                                                    }
                                                                return _w
                                                            })
                                                        }
                                                    ))
                                                }
                                            }}
                                        />
                                        <div>
                                            <div>{record?.name}</div>
                                            <div style={{ color: "#aaa" }}>{formatMessage({ defaultMessage: 'Mã kho: ' })}{record?.code}</div>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: formatMessage({ defaultMessage: 'Địa chỉ kho' }),
                            dataIndex: 'id',
                            key: 'id',
                            align: 'left',
                            width: '60%',
                            render: (item, record) => {
                                return (
                                    <div className='d-flex flex-column'>
                                        {record?.address}
                                    </div>
                                )
                            }
                        },
                    ]}
                    data={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))}
                    emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                        <img src={toAbsoluteUrl("/media/empty.png")} alt="image" width={80} />
                        <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                    </div>}
                    tableLayout="auto"
                    sticky={{ offsetHeader: 0 }}
                />
                <PaginationModal
                    page={page}
                    totalPage={totalPage}
                    limit={5}
                    totalRecord={totalRecord}
                    count={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))?.length}
                    onPanigate={(page) => setPage(page)}
                    emptyTitle={formatMessage({ defaultMessage: 'Chưa có kho' })}
                />
            </Fragment>
        </Modal.Body>
        <Modal.Footer
            className="form p-4 d-flex justify-content-end"
            style={{ borderTop: '1px solid #dbdbdb' }}
        >
            <div className="form-group">
                <button
                    type="button"
                    onClick={() => { _onHideSelectWh() }}
                    className="btn btn-secondary"
                    style={{ width: 120 }}
                >
                    {formatMessage({ defaultMessage: 'Hủy' })}
                </button>

                <button
                    type="submit"
                    className="btn btn-primary ml-2"
                    style={{ width: 120 }}
                    onClick={() => {
                        const _value = values[`sme_warehouse_mapping_${sc_warehouse_id}`]
                        const d = _value?.map(record => {
                            const w = ws?.find(_ => _?.sme_warehouse_id == record?.value);
                            return {
                                ...record,
                                is_process_order: w?.is_process_order
                            }
                        })
                        setFieldValue(`sme_warehouse_mapping_${sc_warehouse_id}`, d?.length == 0 ? null : d)

                        const _fd = d?.filter(_ => _?.is_process_order)
                        const _fv = _value?.filter(_ => _?.is_process_order)

                        if (_fv?.length != _fd?.length) {
                            setFieldValue(`isEdit-${sc_warehouse_id}`, true); // nếu khác nhau về số lượng => có thay đổi
                        }
                        else {
                            // check xem có phần tử nào khác nhau không
                            let isDif = false;
                            _fd?.forEach(item => {
                                if (!_fv?.some(_ => _?.value == item?.value)) {
                                    isDif = true;
                                }
                            })
                            if (isDif) {
                                setFieldValue(`isEdit-${sc_warehouse_id}`, true); // nếu khác nhau về giá trị => có thay đổi
                            }
                        }
                        _onHideSelectWh()
                    }}
                >
                    {formatMessage({ defaultMessage: 'Tiếp tục' })}
                </button>
            </div>
        </Modal.Footer>
    </Modal>
}

const CloseIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg>
}

export default SelectWHProcessDialog