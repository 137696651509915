import React from "react";
import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import "@formatjs/intl-relativetimeformat/dist/locale-data/vi";

import enMessages from "./messages/en-new.json";
import viMessages from "./messages/vi-new.json";

const allMessages = {
  en: enMessages,
  vi: viMessages  
};

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];  

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}
