import gql from "graphql-tag";

export default gql`
  query coGetSettingShippingProvider {
    coGetSettingShippingProvider {
      is_shipping_provider_auto_select
      providers_connected_id
      service_code
      sme_warehouse_ids
    }
  }
`;
