import Table from 'rc-table';
import 'rc-table/assets/index.css';
import React, { Fragment, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';
import { Checkbox } from '../../../../../_metronic/_partials/controls';
import PaginationModal from '../../../../../components/PaginationModal';

export const CHON_KHO_DAY_TON = 1;
export const CHON_KHO_XU_LY_DON = 2;

const SelectWHDialog = ({
    showSelectWh,
    onHideSelectWh,
    sme_warehouses,
    setFieldValue,
    whSelected,
    setWhSelected,
    optionsSmeWarehouse,
    values
}) => {
    const [page, setPage] = useState(1)
    const [searchText, setSearchText] = useState('');
    const { formatMessage } = useIntl();

    const dataWh = (sme_warehouses || []).filter(w => {
        if (!searchText) return true;
        return w?.name?.includes(searchText) || w?.code?.includes(searchText)
    })

    const totalRecord = sme_warehouses?.length || 0;
    const totalPage = Math.ceil(sme_warehouses?.length / 5);
    const { sc_warehouse_id } = showSelectWh;

    const _onHideSelectWh = () => {
        setSearchText('');
        onHideSelectWh()
    }

    return <Modal
        show={!!showSelectWh}
        aria-labelledby="example-modal-sizes-title-xl"
        centered
        size="lg"
        backdrop={true}
        onHide={() => { _onHideSelectWh() }}
    >
        <Modal.Header closeButton={true}>
            <Modal.Title className='d-flex align-items-center justify-content-between' style={{ width: "100%" }}>
                {formatMessage({ defaultMessage: 'Chọn kho đẩy tồn' })}
                <div className='d-flex'
                    onClick={() => {
                        _onHideSelectWh()
                    }}
                    style={{ cursor: "pointer" }}
                >
                    <CloseIcon />
                </div>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
            <Fragment>
                <div className="col-4 input-icon p-0" style={{ height: 'fit-content' }} >
                    <input
                        type="text"
                        className="form-control"
                        placeholder={formatMessage({ defaultMessage: "Tên/Mã kho" })}
                        onBlur={(e) => {
                            setSearchText(e.target.value)
                        }}
                        defaultValue={''}
                        onKeyDown={e => {
                            if (e.keyCode == 13) {
                                setSearchText(e.target.value)
                            }
                        }}
                    />
                    <span><i className="flaticon2-search-1 icon-md ml-6"></i></span>
                </div>
                <div className='py-4'>{formatMessage({ defaultMessage: "Đã chọn:" })} {whSelected[sc_warehouse_id]?.length ?? 0}/<span style={{ color: "#ff5629" }}>{sme_warehouses?.length ?? "--"}</span></div>
                <Table
                    className="upbase-table"
                    style={{ minHeight: 420 }}
                    columns={[
                        {
                            title: <div className='d-flex align-items-center'>
                                <Checkbox
                                    inputProps={{
                                        'aria-label': 'checkbox',
                                    }}
                                    size='checkbox-md'
                                    isSelected={whSelected[sc_warehouse_id]?.length == sme_warehouses?.length}
                                    onChange={(e) => {
                                        if (whSelected[sc_warehouse_id]?.length == sme_warehouses?.length)
                                            setWhSelected(_ => (
                                                {
                                                    ..._,
                                                    [sc_warehouse_id]: []
                                                })
                                            )
                                        else
                                            setWhSelected(_ => (
                                                {
                                                    ..._,
                                                    [sc_warehouse_id]: sme_warehouses?.map(_ => _?.id)
                                                }
                                            ))
                                    }}
                                />
                                {formatMessage({ defaultMessage: 'Tên kho' })}
                            </div>,
                            dataIndex: 'idex',
                            key: 'idex',
                            align: 'left',
                            width: '40%',
                            render: (item, record) => {
                                const whs = whSelected[sc_warehouse_id] ?? [];
                                const selected = !!whs?.find(w => w?.sme_warehouse_id == record?.id)
                                return (
                                    <div className='d-flex align-items-center'>
                                        <Checkbox
                                            inputProps={{
                                                'aria-label': 'checkbox',
                                            }}
                                            size='checkbox-md'
                                            isSelected={selected}
                                            onChange={(e) => {
                                                if (selected)
                                                    setWhSelected(_ => ({
                                                        ..._,
                                                        [sc_warehouse_id]: whs.filter(__ => __?.sme_warehouse_id != record?.id)?.map(_ => ({ ..._, is_process_order: 0 })) // clear luôn is_process_order
                                                    }))
                                                else {
                                                    setWhSelected(_ => (
                                                        {
                                                            ..._,
                                                            [sc_warehouse_id]: [...whs?.map(_ => ({ ..._, is_process_order: 0 })), { sme_warehouse_id: record?.id, is_process_order: 0 }] // clear luôn is_process_order
                                                        }
                                                    ))
                                                }
                                            }}
                                        />
                                        <div>
                                            <div>{record?.name}</div>
                                            <div style={{ color: "#aaa" }}>{formatMessage({ defaultMessage: 'Mã kho: ' })}{record?.code}</div>
                                        </div>
                                    </div>
                                )
                            }
                        },
                        {
                            title: formatMessage({ defaultMessage: 'Địa chỉ kho' }),
                            dataIndex: 'id',
                            key: 'id',
                            align: 'left',
                            width: '60%',
                            render: (item, record) => {
                                return (
                                    <div className='d-flex flex-column'>
                                        {record?.address}
                                    </div>
                                )
                            }
                        },
                    ]}
                    data={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))}
                    emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                        <img src={toAbsoluteUrl("/media/empty.png")} alt="image" width={80} />
                        <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                    </div>}
                    tableLayout="auto"
                    sticky={{ offsetHeader: 0 }}
                />
                <PaginationModal
                    page={page}
                    totalPage={totalPage}
                    limit={5}
                    totalRecord={totalRecord}
                    count={dataWh?.slice(5 * (page - 1), 5 + 5 * (page - 1))?.length}
                    onPanigate={(page) => setPage(page)}
                    emptyTitle={formatMessage({ defaultMessage: 'Chưa có kho' })}
                />
            </Fragment>
        </Modal.Body>
        <Modal.Footer
            className="form p-4 d-flex justify-content-end"
            style={{ borderTop: '1px solid #dbdbdb' }}
        >
            <div className="form-group">
                <button
                    type="button"
                    onClick={() => { _onHideSelectWh() }}
                    className="btn btn-secondary"
                    style={{ width: 120 }}
                >
                    {formatMessage({ defaultMessage: 'Hủy' })}
                </button>

                <button
                    type="submit"
                    className="btn btn-primary ml-2"
                    style={{ width: 120 }}
                    onClick={() => {
                        const d = optionsSmeWarehouse
                            ?.filter(ow => !!whSelected[sc_warehouse_id]
                                ?.find(_ => _?.sme_warehouse_id == ow?.value))
                            ?.map(_ => {
                                // const s = whSelected[sc_warehouse_id]?.find(__ => __?.sme_warehouse_id == _?.value)
                                return { ..._, is_process_order: 0 }
                            })

                        setFieldValue(`sme_warehouse_mapping_${sc_warehouse_id}`, d?.length == 0 ? null : d) // d ở đây đã được filter bỏ đi các kho đã bị bỏ chọn, và khi chọn lại thì làm mới lại is_process_order

                        if (values[`sme_warehouse_mapping_${sc_warehouse_id}`]?.length != d?.length) {
                            setFieldValue(`isEdit-${sc_warehouse_id}`, true); // nếu khác nhau về số lượng => có thay đổi
                        }
                        else {
                            // check xem có phần tử nào khác nhau không
                            let isDif = false;
                            d?.forEach(item => {
                                if (!values[`sme_warehouse_mapping_${sc_warehouse_id}`]?.some(_ => _?.value == item?.value)) {
                                    isDif = true;
                                }
                            })
                            if (isDif) {
                                setFieldValue(`isEdit-${sc_warehouse_id}`, true); // nếu khác nhau về giá trị => có thay đổi
                            }
                        }
                        _onHideSelectWh()
                    }}
                >
                    {formatMessage({ defaultMessage: 'Tiếp tục' })}
                </button>
            </div>
        </Modal.Footer>
    </Modal>
}

const CloseIcon = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
    </svg>
}

export default SelectWHDialog