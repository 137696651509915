import gql from "graphql-tag";

export default gql`
  query userGetCustomTokenFirebase {
    userGetCustomTokenFirebase {
        data
        messge
        success
    }
  }
`;

