import gql from 'graphql-tag';

export default gql`
    query sme_brands($limit: Int, $offset: Int) {
        sme_brands(limit: $limit, offset: $offset, order_by: {updated_at: desc}) {
            created_at
            id
            name
            sme_id
            updated_at
        }
        sme_brands_aggregate {
            aggregate {
                count
            }
        }
    }`