import React, { useMemo, useState } from 'react'
import RcTable from 'rc-table';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import EditVertical from './EditVertical';
import ListWarehouseDialog from './ListWarehouseDialog';


const TableStore = ({ loading }) => {
    const { formatMessage } = useIntl()
    const { values, setFieldValue, errors } = useFormikContext()
    const [showSelectWh, setShowSelectWH] = useState(null);
    const [columns, dataTable] = useMemo(() => {
        let columns = []
        const enable_map_multiple_wh = values['info_store']?.enable_map_multiple_wh;
        let _dataTable = (values['listWarehouseMapping'] ?? [])?.map(wh => ({
            ...wh
        }))

        const dataTable = [];
        const _mapScWh_SmeWh = {};

        if (enable_map_multiple_wh != 0) {
            _dataTable?.forEach(d => {
                const sc_wh_id = d?.scWarehouse?.id;
                if (!_mapScWh_SmeWh[sc_wh_id]) _mapScWh_SmeWh[sc_wh_id] = [];
                if (!!d?.sme_warehouse_id)
                    _mapScWh_SmeWh[sc_wh_id].push(d?.sme_warehouse_id)

                if (!dataTable?.some(item => item?.scWarehouse?.id == sc_wh_id)) {
                    dataTable.push(d)
                }
            })
        }
        else {
            dataTable.push(..._dataTable)
        }

        columns = [
            {
                title: <div className='row col-12'>
                    <span className='col-3'>{formatMessage({ defaultMessage: "Kho kênh bán" })}</span>
                    <span className='col-3'>{formatMessage({ defaultMessage: "Tỷ lệ đẩy" })}</span>
                    <span className='col-3'>{formatMessage({ defaultMessage: "Ngưỡng bảo vệ" })}</span>
                    <span className='col-3'>{formatMessage({ defaultMessage: "Kho vật lý" })}</span>
                </div>,
                render: (record, item) => {
                    const smewh = _mapScWh_SmeWh[item?.scWarehouse?.id]
                    return <>

                        <div className='row col-12'>
                            <div style={{ display: 'grid', gridTemplateColumns: '43% auto', alignItems: 'center' }} className='col-3'>
                                <div className='d-flex justify-content-end'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="mr-2 bi bi-house-door" viewBox="0 0 16 16">
                                        <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z">
                                        </path>
                                    </svg>
                                </div>
                                <span>{item?.scWarehouse?.warehouse_name}</span>
                            </div>
                            {!!item?.name ? (
                                <>
                                    <div style={{ pointerEvents: !Boolean(values['typePush']) ? 'none' : 'auto' }} className='col-3 d-flex align-items-center justify-content-center' >
                                        <span className='mr-2'>{values[`inventory_push_percent-${item?.scWarehouse?.id}`]}%</span>
                                        <EditVertical
                                            type="push"
                                            title={formatMessage({ defaultMessage: "Tỷ lệ đẩy" })}
                                            field={`inventory_push_percent-${item?.scWarehouse?.id}`}
                                            onConfirm={(value) => setFieldValue(`inventory_push_percent-${item?.scWarehouse?.id}`, value)}
                                        />
                                    </div>
                                    <div className='col-3 d-flex align-items-center justify-content-center'>
                                        <span className='mr-2'>{values[`protection_threshold-${item?.scWarehouse?.id}`]}</span>
                                        <EditVertical
                                            type="protection"
                                            title={formatMessage({ defaultMessage: "Ngưỡng bảo vệ" })}
                                            field={`protection_threshold-${item?.scWarehouse?.id}`}
                                            onConfirm={(value) => setFieldValue(`protection_threshold-${item?.scWarehouse?.id}`, value)}
                                        />
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateColumns: '30% 60% 10%', alignItems: 'center' }} className='col-3'>
                                        <div className='d-flex justify-content-end'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="mr-2 bi bi-house-door" viewBox="0 0 16 16">
                                                <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z">
                                                </path>
                                            </svg>
                                        </div>
                                        {enable_map_multiple_wh ? <span>{smewh?.length ?? 0} {formatMessage({ defaultMessage: "kho" })}</span> : <span>{item?.name}</span>}
                                        {!!enable_map_multiple_wh && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye cursor-pointer" viewBox="0 0 16 16"
                                            onClick={() => {
                                                setShowSelectWH(values['listWarehouseMapping']?.filter(_ => {
                                                    return smewh?.includes(_?.sme_warehouse_id)
                                                }))
                                            }}>
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                        </svg>}
                                    </div>
                                </>
                            ) :
                                <>
                                    <div style={{ marginLeft: '14.5%' }}>
                                        <span className='text-danger'>
                                            {formatMessage({ defaultMessage: "Không có liên kết kho nên không thể đẩy tồn được" })}
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center' style={{ flex: 1 }}>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
            }
        ];

        return [columns, dataTable, enable_map_multiple_wh, _mapScWh_SmeWh]

    }, [values, errors])


    return (
        <div>
            <RcTable
                style={loading ? { opacity: 0.4, borderBottom: '1px solid #d9d9d9' } : { borderBottom: '1px solid #d9d9d9' }}
                className="upbase-table"
                columns={columns}
                data={dataTable || []}
                emptyText={<div className='d-flex flex-column align-items-center justify-content-center my-10'>
                    <img src={toAbsoluteUrl("/media/empty.png")} alt="" width={80} />
                    <span className='mt-4'>{formatMessage({ defaultMessage: 'Chưa có dữ liệu' })}</span>
                </div>}
                tableLayout="fixed"
                sticky={{ offsetHeader: 45 }}
            />

            <ListWarehouseDialog
                showSelectWh={showSelectWh}
                onHideSelectWh={() => { setShowSelectWH(null) }}
            />
        </div>
    )
}

export default TableStore